module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sara Fashionwear","start_url":"/","icon":"src/images/icon.png","background_color":"#fefdfd","theme_color":"#9ef3b6","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52d5f1ac0f762aa0054347b47e16c835"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
